import React, { useEffect, useState, useCallback } from 'react';
import {
  useForm,
  FormProvider,
  useWatch,
  Controller,
  useFormState,
  useFormContext,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import getValidationSchema from './validationSchema';
import QuestionComponent, { format } from './QuestionComponent';
import { fetchDocument, fetchForm, saveAnswers } from '../apiServices';
import axios from 'axios';
import config from '../config';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'; // Ensure you have these icons
import Loader from './Loader';

const CategorySelector = ({
  categories,
  activeCategoryIndex,
  setActiveCategoryIndex,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative mb-4">
      <label className="block text-gray-700 text-sm font-semibold mb-2">
        Categorías del formulario
      </label>
      <div
        className="flex justify-between items-center w-full bg-white py-2 px-4 rounded-lg shadow cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-sm font-semibold text-gray-700">
          {categories[activeCategoryIndex]}
        </div>
        <button>
          {isOpen ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </button>
      </div>
      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg flex flex-col">
          {categories.map((category, index) => (
            <button
              key={index}
              className={`text-xs font-semibold px-4 py-2 cursor-pointer text-left ${
                activeCategoryIndex === index
                  ? 'text-blue-500 bg-gray-100'
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
              onClick={() => {
                setActiveCategoryIndex(index);
                setIsOpen(false);
              }}
            >
              {category}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
const Form = () => {
  const [loading, setLoading] = useState(true);
  const [validUser, setValidUser] = useState(false);
  const [questionsByCategory, setQuestionsByCategory] = useState({});
  const [categories, setCategories] = useState([
    'Category 1',
    'Category 2',
    'Category 3',
  ]);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);
  const [dniLoading, setDniLoading] = useState(false);

  const [nameForm, setNameForm] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [previousDNI, setPreviousDNI] = useState('');
  const [disabledFields, setDisabledFields] = useState({});
  const [bankSelected, setBankSelected] = useState('');
  const [sexoSelected, setSexoSelected] = useState('');
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [formUserId, setFormUserId] = useState(null);

  const activeCategory = categories[activeCategoryIndex];
  const methods = useForm({
    resolver: yupResolver(
      getValidationSchema(
        questionsByCategory[activeCategory],
        documentType,
        bankSelected
      )
    ),
    mode: 'onChange',
  }) as any;

  const {
    control,
    formState: { errors },
  } = methods;

  const documentTypeWatch = methods.watch('question_tipo_de_documento');
  const documentNumberWatch = methods.watch('question_numero_de_documento');
  const departmentWatch = methods.watch('question_departamento');
  const provinceWatch = methods.watch('question_provincia');
  const districtWatch = methods.watch('question_distrito');

  const hobbiesWatch = methods.watch('question_hobbies');

  const bankSelection = methods.watch('question_banco');

  const sexSelected = methods.watch('question_sexo');

  const [departments, setDepartments] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [initialProvinces, setInitialProvinces] = useState([]);
  const [initialDistricts, setInitialDistricts] = useState([]);

  const [userId, setUserId] = useState(null);
  const loadData = useCallback(async (formId) => {
    try {
      const [fetchedQuestions] = await Promise.all([fetchForm(formId)]);
      setUserId(fetchedQuestions?.user?.id);
      setDepartments(
        fetchedQuestions?.form_type?.questions.filter(
          (q) => q.description === 'Departamento'
        )[0]?.options
      );
      setInitialProvinces(
        fetchedQuestions?.form_type?.questions.filter(
          (q) => q.description === 'Provincia'
        )[0]?.options
      );
      setInitialDistricts(
        fetchedQuestions?.form_type?.questions.filter(
          (q) => q.description === 'Distrito'
        )[0]?.options
      );
      setNameForm(fetchedQuestions?.form_type?.description);
      const orderedQuestions = fetchedQuestions?.form_type?.questions.sort(
        (a, b) => a.id - b.id
      );
      organizeQuestionsByCategory(orderedQuestions);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (documentTypeWatch) {
      setDocumentType(documentTypeWatch);
    }
  }, [documentTypeWatch]);

  useEffect(() => {
    if (sexSelected) {
      setBankSelected(bankSelection); // Update the state only if there is a change.
    }
  }, [sexSelected]);

  useEffect(() => {
    if (sexoSelected) {
      setSexoSelected(sexoSelected); // Update the state only if there is a change.
    }
  }, [sexoSelected]);

  useEffect(() => {
    if (
      documentNumberWatch &&
      documentNumberWatch.length === 8 &&
      documentTypeWatch == '01'
    ) {
      setPreviousDNI(documentNumberWatch);
      setDniLoading(true);
      fetchDocument(documentNumberWatch, documentTypeWatch)
        .then(async (data) => {
          if (!data) {
            return;
          }
          const normalizedBirthDate = data?.BirthDate?.split('T')[0];
          const month = normalizedBirthDate?.split('-')[1];
          const day = normalizedBirthDate?.split('-')[2];
          const year = normalizedBirthDate?.split('-')[0];
          await methods.setValue('question_pais_emision_documento', '604', {
            shouldDirty: true,
          });
          await methods.setValue(
            'question_fecha_de_nacimiento',
            `${day}/${month}/${year}`,
            { shouldDirty: true }
          );
          await methods.setValue('question_apellido_paterno', data?.Surname1, {
            shouldDirty: true,
          });
          await methods.setValue('question_apellido_materno', data?.Surname2, {
            shouldDirty: true,
          });
          await methods.setValue('question_primer_nombre', data?.Name1, {
            shouldDirty: true,
          });
          await methods.setValue('question_segundo_nombre', data?.Name2, {
            shouldDirty: true,
          });
          await methods.setValue('question_tercer_nombre_o_mas', data?.Name3, {
            shouldDirty: true,
          });
          await methods.setValue('question_nacionalidad', '9589', {
            shouldDirty: true,
          });
        })
        .catch((error) => {
          console.error('Failed to fetch DNI:', error);
          toast.error('Error al buscar DNI');
        })
        .finally(() => {
          setDniLoading(false); // Deactivate the loader
        });
    } else if (
      documentTypeWatch === '04' &&
      documentNumberWatch?.length === 9
    ) {
      setDniLoading(true);
      fetchDocument(documentNumberWatch, documentTypeWatch)
        .then(async (data) => {
          if (!data) {
            return;
          }
          const normalizedBirthDate = data?.BirthDate?.split('T')[0];
          const month = normalizedBirthDate?.split('-')[1];
          const day = normalizedBirthDate?.split('-')[2];
          const year = normalizedBirthDate?.split('-')[0];
          await methods.setValue(
            'question_fecha_de_nacimiento',
            `${day}/${month}/${year}`,
            { shouldDirty: true }
          );
          await methods.setValue('question_apellido_paterno', data?.Surname1, {
            shouldDirty: true,
          });
          await methods.setValue('question_apellido_materno', data?.Surname2, {
            shouldDirty: true,
          });
          await methods.setValue('question_primer_nombre', data?.Name1, {
            shouldDirty: true,
          });
          await methods.setValue('question_segundo_nombre', data?.Name2, {
            shouldDirty: true,
          });
          await methods.setValue('question_tercer_nombre_o_mas', data?.Name3, {
            shouldDirty: true,
          });
        })
        .catch((error) => {
          console.error('Failed to fetch DNI:', error);
          toast.error('Error al buscar DNI');
        })
        .finally(() => {
          setDniLoading(false); // Deactivate the loader
        });
    }
    // map when change the type of document should clean the fields
    else if (
      (documentTypeWatch !== '01' && previousDNI) ||
      documentNumberWatch?.length !== 8
    ) {
      methods.setValue('question_pais_emision_documento', '', {
        shouldDirty: true,
      });
      methods.setValue('question_fecha_de_nacimiento', '', {
        shouldDirty: true,
      });
      methods.setValue('question_apellido_paterno', '', { shouldDirty: true });
      methods.setValue('question_apellido_materno', '', { shouldDirty: true });
      methods.setValue('question_primer_nombre', '', { shouldDirty: true });
      methods.setValue('question_segundo_nombre', '', { shouldDirty: true });
      methods.setValue('question_tercer_nombre_o_mas', '', {
        shouldDirty: true,
      });
      methods.setValue('question_nacionalidad', '', { shouldDirty: true });
    }
  }, [documentNumberWatch, previousDNI, documentTypeWatch]);

  const validateToken = async (token) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${config.REACT_APP_API_URL}/token-info`,
        { token }
      );
      setValidUser(response.data.status === 0);
      setFormUserId(response.data.form_user_id);
      loadData(response.data.form_user_id);
    } catch (error) {
      console.error('Failed to validate token:', error);
      setValidUser(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');
    if (token) {
      validateToken(token);
    } else {
      setLoading(false);
      console.error('Token not found');
      setValidUser(false);
    }
  }, []);

  const errorDepartment = errors['question_departamento'];
  const errorProvince = errors['question_provincia'];
  const errorDistrict = errors['question_distrito'];

  const [questionIdMap, setQuestionIdMap] = useState({});

  const organizeQuestionsByCategory = useCallback((questions) => {
    const categorizedQuestions = {};
    const categoryOrder = [];
    const localQuestionIdMap = {};

    questions.forEach((question) => {
      if (question.question_access.description.toUpperCase() === 'TRABAJADOR') {
        const category = question.question_category.description;
        if (!categorizedQuestions[category]) {
          categorizedQuestions[category] = [];
          categoryOrder.push(category);
        }
        categorizedQuestions[category].push(question);
        localQuestionIdMap[format(question.description)] = question.id;
      }
    });

    setQuestionsByCategory(categorizedQuestions);
    setCategories(categoryOrder);
    setQuestionIdMap(localQuestionIdMap);
  }, []);

  const goToPreviousCategory = () => {
    setActiveCategoryIndex((prev) => prev - 1);
  };

  const onSubmit = async (data) => {
    if (activeCategoryIndex === categories.length - 1) {
      const answers = Object.keys(data)
        .map((key) => {
          const descriptionFormatted = key.split('question_')[1];
          const questionId = questionIdMap[descriptionFormatted];
          const user = userId;
          let answerValue = data[key];
          if (Array.isArray(answerValue) && answerValue.length > 0) {
            answerValue = answerValue[0];
          }
          if (
            answerValue &&
            typeof answerValue === 'object' &&
            'code' in answerValue
          ) {
            answerValue = answerValue.code; // Use the 'code' property
          } else if (!answerValue) {
            answerValue = 'sin respuesta'; // Default if answer is empty or undefined
          }

          if (questionId) {
            return {
              form_user_id: formUserId,
              question_id: questionId,
              answer_text: answerValue,
            };
          }
          return null;
        })
        .filter((answer) => answer !== null);

      try {
        await saveAnswers({ answers });
        toast.success('Respuestas guardadas exitosamente');
        setShowSaveSuccess(true);
      } catch (error) {
        console.error('Failed to save answers:', error);
        toast.error('Error al guardar respuestas');
      }
    } else {
      setActiveCategoryIndex((prev) => prev + 1);
    }
  };

  const hijosFieldNames =
    questionsByCategory[activeCategory] &&
    questionsByCategory[activeCategory].length > 0
      ? questionsByCategory[activeCategory]
          .filter((q) => q.description.toLowerCase().includes('18'))
          .map((q) => `question_${format(q.description)}`)
      : [];

  const hijosValues = useWatch({
    control,
    name: hijosFieldNames,
    defaultValue: hijosFieldNames.reduce((acc, name) => {
      acc[name] = ''; // Initialize each field with a default empty string
      return acc;
    }, {}),
  });

  const sistemaPensionesValue = useWatch({
    control,
    name: 'question_elige_el_sistema_de_pensiones_al_que_aportas',
    defaultValue: '',
  });

  useEffect(() => {
    const newDisabledState = Object.values(hijosValues).some(
      (value) => value === '0'
    );
    if (
      newDisabledState !==
      disabledFields['question__numero_de_hijos_menores_de_edad']
    ) {
      setDisabledFields((prev) => ({
        ...prev,
        question__numero_de_hijos_menores_de_edad: newDisabledState,
      }));
    }
  }, [hijosValues]);

  useEffect(() => {
    const newDisabledState =
      sistemaPensionesValue !== '21' &&
      sistemaPensionesValue !== '23' &&
      sistemaPensionesValue !== '24' &&
      sistemaPensionesValue !== '25';

    setDisabledFields((prev) => ({
      ...prev,
      question_ingresa_tu_cuspp_de_afp: newDisabledState,
    }));
  }, [sistemaPensionesValue]);
  useEffect(() => {
    if (departmentWatch) {
      const departmentCode = parseInt(departmentWatch, 10);
      const filteredProvinces = initialProvinces.filter(
        (p) => p.department_id === departmentCode
      );
      setProvinces(filteredProvinces);
      methods.setValue('question_provincia', '');
      methods.setValue('question_distrito', '');
    } else {
      setProvinces([]);
      methods.setValue('question_provincia', '');
      methods.setValue('question_distrito', '');
    }
  }, [departmentWatch, initialProvinces, methods]);

  useEffect(() => {
    if (provinceWatch) {
      const provinceCode = parseInt(provinceWatch, 10);
      setDistricts(initialDistricts);
      const filteredDistricts = initialDistricts.filter(
        (d) => d.province_id === provinceCode
      );

      setDistricts(filteredDistricts);
      methods.setValue('question_distrito', '', { shouldDirty: true });
    } else {
      methods.setValue('question_distrito', '', { shouldDirty: true });
    }
  }, [provinceWatch]);

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      {validUser ? (
        showSaveSuccess ? (
          <div className="flex flex-col w-full pt-4">
            <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg w-full">
              <h3 className="text-xl font-semibold text-left">
                ¡Gracias por completar el formulario!
              </h3>
              <p className="text-left mt-4">Hemos recibido tus respuestas.</p>
              {/* añadir botón de cerrar pestana */}
              <div className="flex justify-end mt-4">
                <button
                  onClick={() => {
                    window.opener = null;
                    window.open("", "_self");
                    window.close();
                  }}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            {loading || (dniLoading && <Loader />)}
            <div className="flex justify-between bg-white p-4 py-6 rounded-lg shadow-md mb-6">
              <h2 className="text-xl font-semibold">{nameForm}</h2>
            </div>

            <CategorySelector
              categories={categories}
              activeCategoryIndex={activeCategoryIndex}
              setActiveCategoryIndex={setActiveCategoryIndex}
            />

            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="bg-white p-4 rounded-lg"
              >
                {questionsByCategory[activeCategory]?.map((question) => {
                  if (question.description.toLowerCase() === 'departamento') {
                    return (
                      <>
                        <label
                          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
                          htmlFor="question_departamento"
                        >
                          {question.id}. {question.description}
                          {question.is_required === 1 && (
                            <span className="text-red-500 ml-1">*</span>
                          )}
                        </label>
                        <Controller
                          name="question_departamento"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={departments.find(
                                (option) => option.code === field.value
                              )}
                              onChange={(option) => {
                                field.onChange(option ? option.code : '');
                              }}
                              options={departments}
                              isClearable
                              isSearchable
                              getOptionLabel={(option) => option.department}
                              getOptionValue={(option) => option.code}
                            />
                          )}
                        />
                        {question.is_required === 1 &&
                          errorDepartment &&
                          errorDepartment.message && (
                            <p className="text-red-500 text-xs italic">
                              {errorDepartment.message.toString()}
                            </p>
                          )}
                      </>
                    );
                  } else if (
                    question.description.toLowerCase().includes('provincia')
                  ) {
                    return (
                      <>
                        <label
                          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
                          htmlFor="question_provincia"
                        >
                          {question.id}. {question.description}
                          {question.is_required === 1 && (
                            <span className="text-red-500 ml-1">*</span>
                          )}
                        </label>
                        <Controller
                          name="question_provincia"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={provinces.find(
                                (option) => option.id === field.value
                              )}
                              onChange={(option) => {
                                field.onChange(option ? option.id : '');
                              }}
                              options={provinces}
                              isClearable
                              isSearchable
                              getOptionLabel={(option) => option.province}
                              getOptionValue={(option) => option.id}
                            />
                          )}
                        />
                        {question.is_required === 1 &&
                          errorProvince &&
                          errorProvince.message && (
                            <p className="text-red-500 text-xs italic">
                              {errorProvince.message.toString()}
                            </p>
                          )}
                      </>
                    );
                  } else if (
                    question.description.toLowerCase().includes('distrito')
                  ) {
                    return (
                      <>
                        <label
                          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
                          htmlFor="question_distrito"
                        >
                          {question.id}. {question.description}
                          {question.is_required === 1 && (
                            <span className="text-red-500 ml-1">*</span>
                          )}
                        </label>
                        <Controller
                          name="question_distrito"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              value={districts.find(
                                (option) => option.code === field.value
                              )}
                              onChange={(option) => {
                                field.onChange(option ? option.code : '');
                              }}
                              options={districts}
                              isClearable
                              isSearchable
                              getOptionLabel={(option) => option.description}
                              getOptionValue={(option) => option.code}
                            />
                          )}
                        />
                        {question.is_required === 1 &&
                          errorDistrict &&
                          errorDistrict.message && (
                            <p className="text-red-500 text-xs italic">
                              {errorDistrict.message.toString()}
                            </p>
                          )}
                      </>
                    );
                  } else if (
                    question.description.toLowerCase().includes('hobbies')
                  ) {
                    return (
                      <>
                        <label
                          className="block text-gray-700 text-sm font-semibold mb-4 mt-4"
                          htmlFor={`question_${format(question.description)}`}
                        >
                          {question.id}. {question.description}
                        </label>
                        <Controller
                          key={question.id}
                          name={`question_${format(question.description)}`}
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={question.options}
                              onChange={(value) => field.onChange(value)}
                              isClearable
                              isSearchable
                              placeholder="Seleccione una opción"
                              className="text-base w-full mt-1 cursor-pointer"
                              getOptionValue={(option) => option.code}
                              getOptionLabel={(option) => option.description}
                              isMulti
                            />
                          )}
                        />
                      </>
                    );
                  }
                  return (
                    <QuestionComponent
                      key={question.id}
                      question={question}
                      options={question.options}
                      disabled={
                        disabledFields[
                          `question_${format(question.description)}`
                        ]
                      }
                    />
                  );
                })}
                {/* {add validationSchema error dynamically} */}
                {methods.errors}
                <div className="flex justify-between mt-4">
                  <button
                    type="button"
                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                    onClick={goToPreviousCategory}
                    disabled={activeCategoryIndex === 0}
                  >
                    Previo
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
                  >
                    {activeCategoryIndex === categories.length - 1
                      ? 'Finalizar'
                      : 'Siguiente'}
                  </button>
                </div>
              </form>
            </FormProvider>
            <Toaster />
          </>
        )
      ) : (
        // <div>Sin autorización, por favor contacte al administrador.</div> change to a card more formal
        <div className="flex flex-col w-full pt-4">
          <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg w-full">
            <h3 className="text-xl font-semibold text-left">
              Sin autorización, por favor contacte al administrador.
            </h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;
