import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import es from 'date-fns/locale/es';
registerLocale('es', es);

interface Question {
  id: number;
  description: string;
  question_type: {
    description: string;
  };
  options?: any[];
  is_required: number;
}

interface QuestionProps {
  question: Question;
}

export const format = (text: string) => {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/n°/g, 'n');
};

const Label = ({ htmlFor, children, tooltip, isRequired }) => (
  <label
    htmlFor={htmlFor}
    className="block text-gray-700 text-sm font-semibold mb-2"
  >
    {children}
    {isRequired && <span className="text-red-500 ml-1">*</span>}
    {tooltip && (
      <span className="text-xs text-gray-500 ml-1">
        - Verificar en{' '}
        <a
          href="https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx"
          target="_blank"
          rel="noreferrer"
          className="text-blue-500 underline"
        >
          este link
        </a>
      </span>
    )}
  </label>
);

const QuestionComponent: React.FC<
  QuestionProps & {
    disabled?: boolean;
    onChange?: (value: any) => void;
    options?: any[];
  }
> = ({ question, disabled, onChange, options }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const error = errors[`question_${format(question.description)}`] as any;

  const parseDateFromString = (value: string): Date | null => {
    if (!value) return null;
    const parts = value?.split('/');
    if (parts.length === 3) {
      const day = parseInt(parts[0], 10); // Convert day to number
      const month = parseInt(parts[1], 10) - 1; // Convert month to number and adjust for zero index
      const year = parseInt(parts[2], 10); // Convert year to number
      return new Date(year, month, day);
    }
    return null; // Return null if the date string is not in expected format
  };

  return (
    <div className="mb-6">
      <Label
        htmlFor={`question_${question.id}`}
        isRequired={question.is_required === 1}
        tooltip={
          question.description
            .toLocaleLowerCase()
            .includes('sistema de pensiones')
            ? 'Verificar en este link'
            : undefined
        }
      >
        {question.id}. {question.description}
      </Label>
      <Controller
        name={`question_${format(question.description)}`}
        control={control}
        defaultValue=""
        disabled={disabled}
        render={({ field }) => {
          switch (question.question_type.description.toLowerCase()) {
            case 'select':
              return (
                <>
                  <Select
                    {...field}
                    value={question.options?.find(
                      (option) => option.code === field.value
                    )}
                    onChange={(option) =>
                      field.onChange(option ? option.code : '')
                    }
                    options={question.options}
                    getOptionLabel={(option) => option.description}
                    getOptionValue={(option) => option.code}
                    isClearable
                    placeholder="Seleccione una opción"
                  />
                  {error && (
                    <p
                      className="text-red-500 text-xs italic"
                      id={`error-${question.id}`}
                    >
                      {error.message}
                    </p>
                  )}
                </>
              );
            case 'date':
              return (
                <>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={
                      field.value ? parseDateFromString(field.value) : null
                    }
                    onChange={(date) =>
                      field.onChange(
                        `${date.getDate()}/${
                          date.getMonth() + 1
                        }/${date.getFullYear()}`
                      )
                    }
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    locale="es"
                    placeholderText="dd/mm/aaaa"
                    aria-invalid={!!error}
                    aria-describedby={`error-${question.id}`}
                  />
                  {error && (
                    <p
                      className="text-red-500 text-xs italic"
                      id={`error-${question.id}`}
                    >
                      {error.message}
                    </p>
                  )}
                </>
              );

            case 'number':
              return (
                <>
                  <input
                    {...field}
                    type="number"
                    disabled={disabled}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    aria-invalid={!!error}
                    aria-describedby={`error-${question.id}`}
                    min="0"
                    onKeyPress={(e) => {
                      if (e.key === '-' || e.key === '+') e.preventDefault();
                    }}
                  />
                  {error && (
                    <p
                      className="text-red-500 text-xs italic"
                      id={`error-${question.id}`}
                    >
                      {error.message}
                    </p>
                  )}
                </>
              );
            default:
              return (
                <>
                  <input
                    {...field}
                    type="text"
                    disabled={disabled}
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    aria-invalid={!!error}
                    aria-describedby={`error-${question.id}`}
                  />
                  {error && (
                    <p
                      className="text-red-500 text-xs italic"
                      id={`error-${question.id}`}
                    >
                      {error.message}
                    </p>
                  )}
                </>
              );
          }
        }}
      />
    </div>
  );
};

export default React.memo(QuestionComponent);
