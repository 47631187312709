import axios from 'axios';

// Base URL of your API
const API_BASE_URL = `${process.env.REACT_APP_API_URL}`;

// Fetch question types
export const fetchQuestionTypes = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/question-type`);
    return response.data; // Assuming the data comes in the response's data field
  } catch (error) {
    console.error('Failed to fetch question types', error);
    return {};
  }
};

// Fetch statuses
export const fetchStatuses = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/status`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch statuses', error);
    return {};
  }
};

export const fetchForm = async (formId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/form-user/${formId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch questions', error);
    return [];
  }
};

export const fetchDocument = async (documentNumber, documentType) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_RENIEC_URL}/masterDoc?DNIType=${documentType}&DNINumber=${documentNumber}`);
    return response.data[0]
  } catch (error) {
    console.error('Failed to fetch document', error);
    return {};
  }
}

export const saveAnswers = async (answers) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/save-answers`, answers);
    return response.data;
  } catch (error) {
    console.error('Failed to save answers', error);
    return {};
  }
}