interface Config {
  REACT_APP_API_URL: string;
  REACT_APP_RENIEC_URL: string;
}

const config: Config = {
  REACT_APP_API_URL:
    process.env.REACT_APP_API_URL || 'http://localhost:3000/api',
  REACT_APP_RENIEC_URL:
    process.env.REACT_APP_RENIEC_URL || 'http://localhost:3000/reniec',
};

export default config;
